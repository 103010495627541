<template>

  <!---------------------------------------------------------------------------------->
  <!------------------------------ App Vertical Grid --------------------------------->
  <!---------------------------------------------------------------------------------->
  
  <v-dialog v-model="this.$store.state.settings.showRegisterDialog" >
      <annucal-sign-in
      :showCalendarName = true
      @close-menu="closeSignInMenu"
      :creatorUID=this.$store.state.user.userID
      prop_mode="Register"
      :Headline = this.$store.state.settings.AnnucalVcardHeadline
      
    />
  </v-dialog>

  <v-dialog v-model="this.$store.state.settings.showLoginDialog" >
      <annucal-sign-in
      :showCalendarName = true
      @close-menu="closeSignInMenu"
      :creatorUID=this.$store.state.user.userID
      prop_mode="Login"
      :Headline = this.$store.state.settings.AnnucalVcardHeadline
      
    />
  </v-dialog>

  <div 
    class="app-container"
    id="app" @mouseup="stopDrag" @mousemove="onDrag"
    >

    <!---------------------------------------------------------------------------------->
    <!----------------------------------- Navbar --------------------------------------->
    <!---------------------------------------------------------------------------------->
    
    <header class="navbar">
      <div></div>
      <exchange-navbar
          :title="brandName"
          :items="menuItems"
        />
    </header>

    <!---------------------------------------------------------------------------------->
    <!----------------------------------- Main Grid ------------------------------------>
    <!---------------------------------------------------------------------------------->

    <!---------------------------------------------------------------------------------->
    <!----------------------------------- Demo Bar ------------------------------------>
    <!---------------------------------------------------------------------------------->


    <div v-if="this.$store.state.settings.Authenticated === false"
    ref="draggable" class="draggable" v-bind:style="position" @mousedown="startDrag">
  <div 
    class="drag-title annucal-text-18px"
    >
      AnnuCal Demo
  </div>

  <div class="drag-buttons">
    <v-btn 
        class="button-family annucal-text-14px"  
        density="default"  
        variant="flat" 
        height="32"
         :key="buttonKey1"
        :loading = loading1
        @click="loadDemoCalendar(1)">
        <div :style="{ fontWeight: fontWeight1}">
          {{ $t('famlilies')}}
        </div>
       
    </v-btn>
    <v-btn 
        class="button-freelancer annucal-text-14px"  
        density="default"  
        variant="flat" 
        height="32"
        :loading = loading2
        @click="loadDemoCalendar(2)">
        <div :style="{ fontWeight: fontWeight2, color: color2}">
        {{ $t('freelancers')}}
      </div>
    </v-btn>
    <v-btn 
        class="button-traveller annucal-text-14px"  
        density="default"  
        variant="flat"
        height="32" 
        :loading = loading3
        @click="loadDemoCalendar(3)">
        <div :style="{ fontWeight: fontWeight3}">
        {{ $t('travellers')}}
      </div>
    </v-btn>
    <v-btn 
        class="button-blank annucal-text-14px"  
        density="default"  
        variant="flat" 
        height="32"
        :loading = loading4
        @click="loadDemoCalendar(4)">
        <div :style="{ fontWeight: fontWeight4}">
        Blank
      </div>
    </v-btn>
  </div>
  
  <div class="dragbox-text annucal-text-14px">

    <span 
      class="registerdemo"
      @click="openRegisterDialog()"
      >

      {{$t('registerdemo')}}
      
    </span>

    <span>{{$t('or')}}</span>

    <span 
      class="registerdemo"
      @click="openLoginDialog()"
      >{{$t('logindemo')}}</span>
    <span>{{$t('tosaveyourdata')}}</span>
  </div>
</div>


  

    <div class="main-grid">
      <!---------------------------------------------------------------------------------->
      <!----------------------------------- Sidebar -------------------------------------->
      <!---------------------------------------------------------------------------------->
      <!-- Linke Spalte mit dynamischer Breite von 5% -->
      <div class="sidebar">
        <div class="layer-container">
            <calendar-side-control-vue/>
      </div>
    </div>

    <!---------------------------------------------------------------------------------->
    <!------------------------------- Calendar Grid View 1 ----------------------------->
    <!---------------------------------------------------------------------------------->
      <div class="scrollable-grid" v-if="this.$store.state.settings.calendarView == 1" >
        <!-- Scrollbares Grid mit 32 Zeilen und 24 Spalten -->
        <div class="grid">
          <div v-for ="(monthitem, index) in items" v-bind:key="index" > 
              <!--------------------------------- YEAR HEADLINE ----------------------------------->
              <div v-for ="(item, index2) in monthitem" v-bind:key="index2"> 
        
                <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderday :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
              
              </div>
           
          </div>
     
        </div>
   
      </div>

      <!---------------------------------------------------------------------------------->
      <!------------------------------- Calendar Grid View 2 ----------------------------->
      <!---------------------------------------------------------------------------------->

      <div class="scrollable-grid" v-if="this.$store.state.settings.calendarView == 2" >
        <!-- Scrollbares Grid mit 32 Zeilen und 24 Spalten -->
        <div >
          <!--<div v-for ="(monthitem, index) in items" v-bind:key="index" > -->
              <!--------------------------------- YEAR HEADLINE ----------------------------------->
                <div class="grid2" v-for="n in 12" :key="n">
                  <div v-for ="(item, index2) in items[n-1]" v-bind:key="index2"> 
                    <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                      <PopoverCalenderdayHorizontal :item=item />
                    </div>
                    <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                  </div>
                </div>

                 

                
                 <!--<div v-for ="(item, index2) in items[1]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[2]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[3]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[4]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[5]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[6]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[7]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[8]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[9]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[10]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>
                <div v-for ="(item, index2) in items[11]" v-bind:key="index2"> 
                  <div style="z-index: 0;" v-if="!item.id.includes('noday')">
                  <PopoverCalenderdayTranspose :item=item />
                </div>
                <div style="z-index: 0;" v-if="item.id.includes('noday')"></div>
                </div>-->
               
              

             
        
                <!--<div v-if="!item.id.includes('noday')" style="z-index: 0;">


                  <PopoverCalenderdayTranspose 
                    :item=item 
                  />

                </div>-->

                <!--<div style="z-index: 0;" v-if="item.id.includes('noday')"></div>-->
              
           
           
         <!-- </div>-->
     
        </div>
   
      </div>
 
    </div>

  </div>
</template>

<script>

import ExchangeNavbar from "./components/ExchangeNavbar.vue";
import CalendarSideControlVue from "./components/CalendarSideControl.vue"
import PopoverCalenderdayHorizontal from "./components/PopoverCalenderdayHorizontal.vue";
import PopoverCalenderday from "./components/PopoverCalenderday";
import AnnucalSignIn from "./components/AnnucalSignIn.vue"
import calenderCore from "./composition/calenderCore";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import { v4 as uuidv4 } from 'uuid';
import { useI18n } from 'vue-i18n'
import { getAuth } from "firebase/auth";

import demoFamilyCalendar from '@/assets/demo_family_calenders.json'
import demoFamilyEvents from '@/assets/demo_family_events.json'
import demoFreelancerCalendar from '@/assets/demo_freelancer_calenders.json'
import demoFreelancerEvents from '@/assets/demo_freelancer_events.json'
import demoTravellerCalendar from '@/assets/demo_traveller_calenders.json'
import demoTravellerEvents from '@/assets/demo_traveller_events.json'
import demoBlankCalendar from '@/assets/demo_blank_calenders.json'
import demoBlankEvents from '@/assets/demo_blank_events.json'

import {db} from "./db";
import { doc, getDoc } from "firebase/firestore";

import Intercom from '@intercom/messenger-js-sdk';
import { shutdown } from '@intercom/messenger-js-sdk';

import { useRoute } from 'vue-router';




export default {

  setup() {
    const { locale } = useI18n();
    const calenderCore1 = calenderCore();
    const store = useStore();
    const { state } = store;

    const route = useRoute();
    const demoValue = computed(() => route.query.demo);

    const { t } = useI18n()

    //const browserLanguage = navigator.language.split('-')[0];

    const fontWeight1 = ref(400)
    const fontWeight2 = ref(400)
    const fontWeight3 = ref(400)
    const fontWeight4 = ref(400)

    const color1 = ref('#191816')
    const color2 = ref('#191816')
    const color3 = ref('#191816')
    const color4 = ref('#191816')

    const loading1 = ref(false)
    const loading2 = ref(false)
    const loading3 = ref(false)
    const loading4 = ref(false)

    const buttonKey1 = ref(0);

    const topheadline = ref(t('menu') )



    async function loadDemoCalendarFinal(index){
      switch(index){
        case 1: 
          state.mycalendars.items = demoFamilyCalendar
          state.storedevents.items = demoFamilyEvents
          fontWeight1.value = 600
          fontWeight2.value = 400
          fontWeight3.value = 400
          fontWeight4.value = 400
          color1.value = '#ffffff'
          color2.value = '#191816'
          color3.value = '#191816'
          color4.value = '#191816' 
      }
    }
   
    async function loadDemoCalendar(index){
      switch(index) {
        case 1:
        buttonKey1.value++;
          loading1.value = true
          buttonKey1.value++;
          await loadDemoCalendarFinal(index)

        break;
        case 2:
          state.mycalendars.items = demoFreelancerCalendar
          state.storedevents.items = demoFreelancerEvents
          fontWeight1.value = 400
          fontWeight2.value = 600
          fontWeight3.value = 400
          fontWeight4.value = 400
          color1.value = '#191816'
          color2.value = '#ffffff'
          color3.value = '#191816'
          color4.value = '#191816'
          break;
        case 3:
          state.mycalendars.items = demoTravellerCalendar
          state.storedevents.items = demoTravellerEvents
          fontWeight1.value = 400
          fontWeight2.value = 400
          fontWeight3.value = 600
          fontWeight4.value = 400
          color1.value = '#191816'
          color2.value = '#191816'
          color3.value = '#ffffff'
          color4.value = '#191816'
          break;
        case 4:
          state.mycalendars.items = demoBlankCalendar
          state.storedevents.items = demoBlankEvents  
          fontWeight1.value = 400
          fontWeight2.value = 400
          fontWeight3.value = 400
          fontWeight4.value = 600  
          color1.value = '#191816'
          color2.value = '#191816'
          color3.value = '#191816'
          color4.value = '#ffffff'   
          break;
      }
      
      state.events.items = state.storedevents.items
      calenderCore1.updateCalender();
      loading1.value = false

    }

    watch(
      () => route.query.demo,
      (newValue) => {
        console.log('Demo-Parameter geändert:', newValue);
        loadDemoCalendar(Number(newValue))
      },
      { immediate: true } // Reagiere sofort auf den initialen Zustand
    );
        // Initiale Daten laden
        onMounted(() => {
          // Stelle sicher, dass route.query existiert
          
          console.log( console.log(demoValue)); // Erwarteter Wert: 'family'); // Erwarteter Wert: 'family'
        });

    function openLoginDialog(){
      state.settings.AnnucalSignInPageVisible = true
      state.settings.AnnucalVcardHeadline = t("login")
      state.settings.AnnucalSaveText = t("login")
      state.settings.Log_Status = "login"
      state.settings.showRegister = true
      state.settings.showForgotPassword = false
      state.settings.showRegisterDialog = true
      topheadline.value = t("login")
    }

    function openRegisterDialog(){
      state.settings.AnnucalSignInPageVisible = true
      state.settings.AnnucalVcardHeadline = t("Register")
      state.settings.AnnucalSaveText = t("Register")
      state.settings.Log_Status = "register"
      state.settings.showRegister = true
      state.settings.showForgotPassword = false
      state.settings.showRegisterDialog = true
      topheadline.value = t("Register")
    }


    function downloadJSONFile() {

      const filename1 = "demo_family_calendars.json"
      const data1 = state.mycalendars.items
      //const data = state.storedevents.items
      
      const jsonString = JSON.stringify(data1, null, 2);
      const blob1 = new Blob([jsonString], { type: 'application/json' });
      const url1 = URL.createObjectURL(blob1);
      const link1 = document.createElement('a');
      link1.href = url1;
      link1.download = filename1;
      link1.click();
      URL.revokeObjectURL(url1);

      const filename2 = "demo_family_events.json"
      const data2 = state.storedevents.items
      
      const jsonString2 = JSON.stringify(data2, null, 2);
      const blob2 = new Blob([jsonString2], { type: 'application/json' });
      const url2 = URL.createObjectURL(blob2);
      const link2 = document.createElement('a');
      link2.href = url2;
      link2.download = filename2;
      link2.click();
      URL.revokeObjectURL(url2);


    }

    return {
      currentLanguage: locale,
      loadDemoCalendar,
      downloadJSONFile,
      openRegisterDialog,
      openLoginDialog,
      fontWeight1,
      fontWeight2,
      fontWeight3,
      fontWeight4,
      loading1,
      loading2,
      loading3,
      loading4,
      buttonKey1,
      route,
      demoValue
    };
  },

  name: "MyGridComponent",
  components: {
    ExchangeNavbar,    
    CalendarSideControlVue,
    PopoverCalenderday,
    PopoverCalenderdayHorizontal,
    AnnucalSignIn
    
  },

  data(){
      return {
        insertEventisOpen: false,
        deleteEventisOpen: false,
        selectedDate: "",
        selectedEvent: "",
        deleteBackgroundColor: "#00ff00",
        calenderCore1 : calenderCore(),
        uniqueId: uuidv4(),
        t: useI18n(),
        isDragging: false,
      offsetX: 0,
      offsetY: 0,
      position: {
        left: 'calc(50% - 225px)',
        top: '10px',
      },
      buttons: [
        { active: false },
        { active: false },
        { active: false },
      ],
    
    
      }
    },
   
    computed:{

        items(){
              return this.$store.state.calender.items;
        },
        events(){
              return this.$store.state.events.items;
        },
        
    },
  
        async created(){
          this.$store.dispatch("getCalender");
          this.$store.dispatch("getTimezones");
          this.$store.dispatch("getPublicHolidays"); 
          this.$store.dispatch("getShoolholidays"); 
          this.$store.dispatch("getContent"); 
            
          const calenderCore1 = calenderCore();
          calenderCore1.updateCalender();
  
          const store = useStore();
          const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
          
  
          //--------------------------------------------------------------------------------------------//
          //-------------------------------- If is authenticated ---------------------------------------//
          //--------------------------------------------------------------------------------------------//
          
          if(isAuthenticated.value === true){
            this.$store.state.settings.Authenticated = true
            if(this.$store.state.settings.fetchedDataFromDb == false){
              await this.$store.dispatch("getmyCalendarsFromDB");
              await this.$store.dispatch("getmyEventsFromDB",this.$store.state.user.userID);
              await this.getIntercomHmac(this.$store.state.user.userID) // plus User settings
              await this.$store.dispatch("refreshIndexList");
              await this.$store.dispatch("getavailableCalendarsFromDB", this.$store.state.user.userID);
              await store.dispatch('getPublicHolidaysFromApi', { userId: this.$store.state.user.userID, localCalendars: this.$store.state.storedmycalendars.items, language: this.$i18n.locale });
              this.$store.state.mycalendars.items = this.$store.state.storedmycalendars.items
              this.$store.state.availablecalendars.items = this.$store.state.storedavailablecalendars.items
              this.$store.state.settings.fetchedDataFromDb = true;
              this.$store.state.events.items = this.$store.state.storedevents.items
              await this.$store.dispatch("getmydaysWithEventsFromDB", {
                    events: this.$store.state.events.items, 
                    myCalendars: this.$store.state.mycalendars.items, 
                    calender: this.$store.state.calender.items});
              calenderCore1.updateCalender();
              this.$store.state.settings.startPageOpen = false; 
              this.startIntercomSignedIn()
            }
          }
      
          //--------------------------------------------------------------------------------------------//
          //-------------------------------- If is NOT authenticated -----------------------------------//
          //--------------------------------------------------------------------------------------------//
  
          else{
            await this.$store.dispatch("initEventItems");
            this.$store.state.events.items = this.$store.state.storedevents.items
            this.addFirstCalendarIfNotSignedIn()
            calenderCore1.updateCalender();
            this.$store.state.settings.startPageOpen = false; 
            this.startIntercomAnonym()
            
          }
  
        },

       
  
         async mounted() {
          window.addEventListener('resize', this.getDimensions);
          document.addEventListener( "keydown", this.onKeydown );
          document.addEventListener( "keyup", this.onKeyup );
          
          if(window.navigator.language.substring(0, 2) == "de"){
            this.$i18n.locale = 'de'
          }else
          {
            this.$i18n.locale = 'en'
          }
     
        },
  
        unmounted() {
          window.removeEventListener('resize', this.getDimensions);
        },
  
        methods: {

          shutdownintercom(){
            try{
              shutdown()
              console.log("Shutted down")
              
            }catch(e){
              console.log(e)
            }
          },

          //----------------------------------------------------------------
          //------------------ Start Intercom not sigend in ----------------
          //----------------------------------------------------------------

          startIntercomAnonym(){
            try{
              
                Intercom({
                app_id: this.$store.state.settings.intercomAppId,
            });
            }catch(e){
              console.log(e)
            }
          },

          //----------------------------------------------------------------
          //------------------ Start Intercom signed in --------------------
          //----------------------------------------------------------------

          startIntercomSignedIn(){
            try{
              
              const auth = getAuth();
              const user = auth.currentUser;

              if (user) {
                const creationTime = user.metadata.creationTime;

              // convert to  Unix-Timestamp 
              const timestamp = Math.floor(new Date(creationTime).getTime() / 1000);
              this.creationTimestamp = timestamp;

              } else {
                console.error("Kein Benutzer angemeldet.");
              }
            
              Intercom({
                app_id: this.$store.state.settings.intercomAppId,
                user_id: this.$store.state.user.userID,
                user_hash: this.$store.state.settings.intercomHmac,
                email:user.email,
                created_at:this.creationTimestamp,
                name: '', 
            });
            }catch(e){
              console.log(e)
            }
          },

          //----------------------------------------------------------------
          //----------- Get Intercom Hmac from Signed in user --------------
          //----------------------------------------------------------------

          async getIntercomHmac(userId){
            try {
                const userDocRef = doc(db, "users", userId);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                  const data = userDoc.data();
                  this.$store.state.settings.intercomHmac = data.Intercomhmac
                  this.$i18n.locale = data.lang
                  this.$store.state.settings.showKalenderwochen = data.calendarweek
                  this.$store.state.settings.showCurrentDay = data.currentDay
                } else {
                  console.error(`Dokument mit ID ${userId} existiert nicht.`);
                  return null;
                }
              } catch (error) {
                console.error("Fehler beim Abrufen des Intercomhmac:", error);
                throw error;
              }
          },




        //----------------------------------------------------------------
        //----------- DRAG & DROP  --------------
        //----------------------------------------------------------------

          startDrag(event) {
            const draggable = this.$refs.draggable;
            this.isDragging = true;
            this.offsetX = event.clientX - draggable.offsetLeft;
            this.offsetY = event.clientY - draggable.offsetTop;
          },
          stopDrag() {
            this.isDragging = false;
          },
          onDrag(event) {
            if (this.isDragging) {
              this.position.left = `${event.clientX - this.offsetX}px`;
              this.position.top = `${event.clientY - this.offsetY}px`;
            }
          },
          setDemoCalendar(index) {
            //this.buttons[index].active = !this.buttons[index].active;
            console.log("DemoCalendar: " + index)
          },
  
          addFirstCalendarIfNotSignedIn(){
            const id = uuidv4()
            let newCalendar = {
              color: '#6AD069', //calendarColor.value, 
              icon: 'ph-CalendarCheck', //icon.value,
              name: 'Privat',
              externalCalendarLink: '',//externalCalendarLink.value,
              description: '',
              visible: true,
              private: true,
              creatorUID: 'notSignedIn',
              creatorName: "Annucal",
              calendarImage:  "",
              type: '1',
              specialday:'',
              country: '',
              subdivision: '',
              subdivisionCode: '',
              loadedPublicHolidays: false,
              calendarId:id,
              docId:id,
              events:[]
          }
    
          this.$store.state.mycalendars.items.push(newCalendar)
          
          this.calenderCore1.updateCalender();
  
          },
          
  
          getDimensions() {
            this.$store.dispatch("getCalender"); 
          },
  
          onKeyup (e) {
            if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = false;
            this.$store.state.settings.selectedCursor = 'default'
            
          }
          },
  
          onKeydown  (e) {
          if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = true;
            this.$store.state.settings.selectedCursor = 'grab'
            
          }
        }      
      },

    
    
};
</script>

<style scoped>

.cell-svg {
  position: absolute;
  right: 4px; /* Rechtsbündig in der Zelle */
  top: 50%;
  transform: translateY(-50%); /* Vertikal zentriert */
  z-index: 1;
}

/* Hauptcontainer, der den gesamten Bildschirm abdeckt */
.app-container {
  display: grid;
  grid-template-rows: 36px 1fr; /* Navbar nimmt mindestens 50px oder bis zu 5% der Höhe ein */
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #E7E5DF;
  
}

/* Navbar */
.navbar {
  display: grid;
  grid-template-columns: 50px 1fr; /* Sidebar nimmt mindestens 50px oder bis zu 5% der Breite ein */
  background-color: #E7E5DF;
  color: white;
  font-size: min(calc(1vh + 1vw), 1.5rem); /* Dynamische Schriftgröße für die Navbar mit einer Mindestgröße */
  height: 100%; /* Passt sich an die Höhe von 5% an */
  
}

/* Hauptbereich mit zwei Spalten */
.main-grid {
  display: grid;
  grid-template-columns: 60px 1fr; /* Sidebar nimmt mindestens 50px oder bis zu 5% der Breite ein */
  /*  height: calc(100vh - min(100px, 5%));Verbleibende Höhe nach der Navbar */
  overflow: hidden;
  padding-bottom: 12px;  
  
}

/* Linke Spalte mit 5% Breite */
.sidebar {
  overflow-y: auto;
  background-color: #E7E5DF;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
}


/* Scrollbarer Bereich für das gesamte Grid */
.scrollable-grid {
  overflow-x: auto;
  background-color: #E7E5DF;
  height: 100%; /* Füllt die verfügbare Höhe aus */
}

/* Grid-Struktur mit 32 Zeilen und 24 Spalten */
.grid1 {
  display: grid;
  grid-template-columns: repeat(24, minmax(1vw, 1fr)); /* 24 Spalten */
  /*height: calc(100vh - min(50px, 5%));  Verbleibende Höhe nach der Navbar */
  height: 100vh;
  height: 100%; /* Füllt den vertikalen Raum vollständig aus */
  width: 200%; /* Macht 12 Spalten sichtbar, der Rest scrollt */

}

/* Grid-Struktur mit 32 Zeilen und 24 Spalten */
.grid {
  display: grid;
  grid-template-columns: repeat(24, minmax(1vw, 1fr)); /* 24 Spalten */
  /*height: calc(100vh - min(50px, 5%));  Verbleibende Höhe nach der Navbar */
  height: 100vh;
  height: 100%; /* Füllt den vertikalen Raum vollständig aus */
  width: 200%; /* Macht 12 Spalten sichtbar, der Rest scrollt */

}

.grid2 {
  display: grid;
  grid-template-columns: repeat(31, minmax(1vw, 1fr)); /* 24 Spalten */
  /*height: calc(100vh - min(50px, 5%));  Verbleibende Höhe nach der Navbar */
  height: 100vh;
  height: 100%; /* Füllt den vertikalen Raum vollständig aus */
  width: 100%; /* Macht 12 Spalten sichtbar, der Rest scrollt */

}

/* Monat-Zelle für die erste Zeile */
.month-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  background-color: #e0e0e0;
  font-weight: bold;
  font-size: min(calc(0.9vh + 0.5vw), 1.2rem); /* Dynamische Schriftgröße für die Monatszeile */
}

/* Stil für den Monatstext */
.month-text {
  font-size: 1em;
}

/* Stil für das Jahr 2024, kleinere Schrift */
.year-text {
  font-size: 0.8em;
  margin-left: 4px;
  color: #666;
}

/* Einheitliche Zellen */
.grid-cell {
  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-size: min(calc(0.8vh + 0.6vw), 1rem); /* Dynamische Schriftgröße für nummerierte Zellen mit Mindestgröße */
}

/* Inneres Grid in jeder nummerierten Zelle */
.inner-grid {
  display: grid;
  grid-template-columns: 1fr 3fr; /* 2 Spalten, wobei die zweite dreimal so breit ist wie die erste */
  width: 100%;
  height: 100%;
  gap: 4px;
}

/* Zellen des inneren Grids */
.inner-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  /*font-size: min(calc(0.6vh + 0.5vw), 0.7rem); *//* Kleinere dynamische Schriftgröße für inneres Grid mit Mindestgröße */
  border: 1px solid #bbb;
}

/* Bei Bildschirmen unter 800px: feste Breite der Spalten */
@media (max-width: 1430px) {
  .grid {
    grid-template-columns: repeat(24, minmax(114px, 1fr)); /* Feste Breite von 80px pro Spalte */
    width: auto; /* Passt die Breite an die Spaltenanzahl an */
  }
}
/* Bei Bildschirmen unter 800px: feste Breite der Spalten */
@media (min-width: 1430px) {
  .grid {
    grid-template-columns: repeat(24, minmax(8.33%, 1fr)); /* Feste Breite von 80px pro Spalte */
    width: auto; /* Passt die Breite an die Spaltenanzahl an */
  }
}

/* Bei Bildschirmen unter 750px: feste Höhe der Zeilen */
@media (max-height: 750px) {
  .grid {
    grid-template-rows: repeat(32, 5vw); /* Feste Höhe von 24px pro Zeile */
    height: auto; /* Ermöglicht vertikales Scrollen bei kleinerer Höhe */
  }
  .grid-cell,
  .month-cell,
  .inner-cell {
    font-size: 0.6rem; /* Kleinere, feste Schriftgröße bei geringer Höhe */
  }
}

.layer-container{
  /*background-color: var(--calendar-background-color); */
  background-color: white;
  z-index: 2;
  width: 40px;
  border-radius: 6px;
}

.draggable {
  border-radius: 8px;
  height: 130px;
  background: #F6F2EF;
  color: white;
  text-align: start;
  line-height: 60px;
  position: absolute;
  cursor: grab;
  user-select: none;
  z-index: 1000;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}



.button {
  background: #95a5a6;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  height: 24px;
}

.button.active {
  background: #e74c3c;
}

.drag-buttons{
  display: flex;
  padding: 16px;
}

.button-family:hover{
  background-color: #E7E5DF;

}

.button-family{
  
  background: #C6C3BB;
  color: #191816;
  text-transform: none !important;
  margin-right: 12px;
}

.button-traveller:hover{
  
  background-color: #E7E5DF;
 
}

.button-traveller{
  background: #C6C3BB;
  color: #191816;
  text-transform: none !important;
  margin-right: 12px;
}

.button-freelancer:hover{
  background-color: #E7E5DF;

}

.button-freelancer{
  
  background-color: #C6C3BB;
  color: #191816;
  text-transform: none !important;
  margin-right: 12px;
}

.button-blank:hover{
  background-color: #E7E5DF;

}

.button-blank{
  background-color: #C6C3BB;
  color: #191816;
  text-transform: none !important;
}

.dragbox-text{
  padding-left: 16px;
  padding-right: 16px;
  color: #191816;
  text-align:center
}

.drag-title{
  margin-top:12px;
  color: #191816;
  text-align: center;
}

.registerdemo:hover{
  
  cursor: pointer
}

.registerdemo{
  font-weight: 600;
  
}

.drag-buttons {
  display: flex;
  gap: 8px; /* Abstand zwischen Buttons */
  justify-content: space-between; /* Buttons gleichmäßig verteilen */
}

.drag-buttons v-btn {
  flex: 1; /* Jeder Button nimmt den gleichen Platz ein */
  text-align: center;
}

</style>






















