
  export default {
  
      state(){
          return{
            settingsPageIsVisible: false,
            showFeiertage: false,
            showSchulferien: false,
            showKalenderwochen: false,
            showCurrentDay:true,
            bundesLaender: '',
            FirstDayOfEventSelected: false,
            LastDayOfEventSelected: false,
            mouseDownEventStartDate: false,
            mouseDownEventEndDate: false,
            addDayOnEvent: false,
            ismousedown: false,
            selectedEventIDtoChangeDate: "",
            selectedEventIndexToChange: -1,
            slectedDayId: "",
            SlotHeader: "",
            ShowDeleteButton: false,
            SlotMode: "",
            SlotColor: "",
            eventName: "",
            eventColor: "#eeeeee", 
            currentEventId: "",
            currentEventIdDB: "",
            currentSelectedCalendar: null,
            currentCalendarName: "Calendar",
            currentCalendarColor: "#ffddrr",
            currentCalendarIcon: "default",
            currentCalendarId: "-1",
            currentVisibleCalendars: [],
            BackColorWeekend: "#F6F2EF",
            BackColorNormalDay: "#ffffff",
            isdragging: false,
            selectedCursor: 'default',
            selectedYear: 2024,
            ctrlPressed: false,
            FixedWithMousUp: false,
            fetchedDataFromDb: false,
            userID: "not defined",
            userName: "not defined",
            Authenticated: false,
            currentdatefrom: "",
            currentdateto: "",
            currentdatefromJson: "",
            currentdatetoJson: "",
            eventNeedsSaving: "no",
            popOverCalenderOpen: false,
            startPageOpen: true,
            NewCalendarPageOpen: false,
            introPageToShow: 6,
            currentRealDate:"",
            currentDraggingEventIndex: -1,
            screenWidth: 0,
            canvasOffScreenVisible: false,
            hamburgerMenueVisible: false,
            currentTextfield1: "x123",
            currentTextfield2: "",
            currentTextfield3: "",
            currentTheme:'light',
            AnnucalSignInPageVisible: false,
            AnnucalSettingsPageVisible: false,
            emailErrorMessage: "",
            passwordErrorMessage:"",
            normlaErrorMessage:"",
            emailAdress:"",
            savingModus:false,
            AnnucalVcardHeadline:"",
            AnnucalSaveText:"",
            AnnucalDeleteText:"",
            Log_Status:"",
            logInEmail:"",
            logInPassword:"",
            logInPasswordConfirmation:"",
            showRegister:false,
            showForgotPassword:false,
            pushAndPullMode:false,
            newEventMode: false,
            pushAndPullcurrentEvent:null,
            pushAndPullTarget:"", 
            showPhushAndPushBoxes:false,
            curentActivePushAndPullCalendarId: '',
            arrowsVisible: false,
            currentCalanderArrows:{calendarId:'',
              dayUpId: '',
              dayDownId: ''
           },
           currentDragginEventIdDB: '',
           currentDraggingCalendarId: '',
           currentDraggingDayId: '',
           hoveredDiv2:null,
           showEditEventMenu: false,
           calendarView: 1,
           currentCalendarType: 1,
           resetPasswordEmail:'',
           resetPasswordSuccess: false,
           showRegisterDialog: false,
           showLoginDialog: false,
           intercomHmac: "",
           intercomAppId: "kan7k9bc",
           savingSettings: false,
           columnWidth: 4.13
          }
      },
  }
  
   